import './App.css';
import { Routes , Route, BrowserRouter} from 'react-router-dom';
import Home from './pages';
import KotiK from './pages/kotikaynnit';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kotikaynnit" element={<KotiK />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
