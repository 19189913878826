import theme from '../theme/theme';

export const homeObjOne = {
    bgcolor: `${theme.colors.secondary}`,
    bgcolorMobile: `${theme.colors.secondary}`,
    dark: true,
    darkMobile: true,
    lightText: false,
    imgStart: true,
    imgWidth: 0.35,
    id: 'palvelut',
    topLine: 'Fysioterapiaa',
    headLine: 'lääkärin lähetteellä tai ilman',
    description: `Fysioterapiakäynti voidaan toteuttaa vastaanotolla tai kotikäyntinä.`,
    img: require('../../images/webp/pallot.webp'),
    alt:'pallot'
};

export const homeObjTwo = {
    bgcolor: `${theme.colors.primary}`,
    bgcolorMobile: `${theme.colors.secondary}`,
    dark: false,
    darkMobile: true,
    lightText: false,
    imgStart: false,
    imgWidth:0.4,
    id: 'lapset',
    topLine: '',
    headLine: 'Lasten Fysioterapia',
    description: `Lapsen kasvun ja sensomotorisen kehityksen tukemista fysioterapian keinoin, lapsen kiinnostuksen kohteet ja ikätaso huomioiden.
    
Toteutetaan siellä, missä lapsen tai nuoren arki on: koti-, päiväkoti-, koulukäynteinä tai toimipisteellä. 
    
Tuoreimpina koulutuksina:  NDT-Johdanto 2022, AIMS-koulutus 2020 ja TAMK Lasten fysioterapia -täydennyskoulutus 2019 `,
    
    img: require('../../images/lastenterapia2.webp'),
    alt:'lampaat'
    
};

export const homeObjTree = {
    bgcolor: `${theme.colors.secondary}`,
    bgcolorMobile: `${theme.colors.secondary}`,
    dark: true,
    darkMobile: true,
    lightText: false,
    imgStart: true,
    imgWidth:0.4,
    id: 'muut-palvelut',
    topLine: '',
    headLine: 'Muut palvelut',
    description: `Hieronta ja muu manuaalinen käsittely

Kinesioteippaus ja urheiluteippaus`,
    img: require('../../images/teipit.jpg'),
    alt:'teipit'
};

export const homeObjMaksutavat = {
    bgcolor: `${theme.colors.primary}`,
    bgcolorMobile: `${theme.colors.primary}`,
    dark: false,
    darkMobile: false,
    lightText: false,
    imgStart: false,
    imgWidth: 0.4,
    id: 'maksutavat',
    topLine: '',
    headLine: 'Maksutavat',
    description: `Pankki-/luottokortti
Lasku
MobilePay
Käteinen
ePassiHyvinvointi
SmartumPay
Satakunnan hyvinvointialueen palveluseteli`,
    img: require('../../images/webp/maksut2.png'),
    alt:'maksut'
};

export const homeObjYhteystiedot = {
    bgcolor: `${theme.colors.background}`,
    bgcolorMobile: `${theme.colors.background}`,
    dark: true,
    darkMobile: true,
    lightText: true,
    imgStart: true,
    imgWidth: 0.3,
    id: 'yhteystiedot',
    topLine: 'Yhteystiedot',
    headLine: 'Minna Repo',
    description:`P. 040 757 4567

tmi.minnarepo(at)gmail.com

Ulvila    Loukkurantie 2

Ft Voluntas 
Pori       Hevoshaankatu 9
`,
    img: require('../../images/webp/laput.webp'),
    alt:'kuva'
};
